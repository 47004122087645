/**
 * Creates a page to show the GoVirginia information.
 * @returns the GoVirginia page.
 */
const GoVirginia = () => {
  return (
    <body className="App-header">
      <h1 style={{ paddingVertical: 5, fontSize: 18 }}>GoVirginia</h1>
      <p>Coming soon</p>
    </body>
  );
};

export default GoVirginia;
